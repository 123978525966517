import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";
import HeroEnd from "../../components/hero-end";

const InteriorDesignersPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "16.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				jessC: file(relativePath: { eq: "testimonials/Jessica Cox.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				IngaK: file(relativePath: { eq: "testimonials/Inga kopala.webp" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				daniel: file(
					relativePath: { eq: "testimonials/daniel-brookbank.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				laurenceK: file(
					relativePath: { eq: "testimonials/Laurence Katz.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				andrew: file(relativePath: { eq: "testimonials/selda-koydemir.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const jessC = data.jessC.childImageSharp.gatsbyImageData;
	const schemaImage = data.heroImg.publicURL;
	const title = "Interior Designers | Business & Executive Coaching in London";
	const description =
		"By partnering with a business coach, you gain a strategic partner who can help you navigate the complexities of the interior design industry, from client acquisition and project management to financial planning and brand development.";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const daniel = data.daniel.childImageSharp.gatsbyImageData;
	const andrew = data.andrew.childImageSharp.gatsbyImageData;
	const IngaK = data.IngaK.childImageSharp.gatsbyImageData;
	const laurenceK = data.laurenceK.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Industries",
				item: {
					url: `${siteUrl}/industries`,
					id: `${siteUrl}/industries`,
				},
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Interior Designers",
				item: {
					url: `${siteUrl}/industries/interior-designers`,
					id: `${siteUrl}/industries/interior-designers`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/industries/interior-designers`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Business & Executive Coach for Interior Designers in London",
						},
					],
				}}
			/>
			<HeroEnd
				isDarken={true}
				backgroundImage={heroImage}
				title="Business & Executive Coach for Interior Designers"
				description1=""
				description2=""
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<p>
								Succeeding in the competitive world of interior design requires
								more than just an eye for design and a flair for aesthetics. It
								also demands a strategic approach to business management. It’s
								essential not only to work 'in' your design projects but also to
								work 'on' your business by fine tuning your marketing,
								optimising your operations, and ensuring business profitability
								and growth.
							</p>
							<p>
								By partnering with a business coach, you gain a strategic
								partner who can help you navigate the complexities of the
								interior design industry, from client acquisition and project
								management to financial planning and brand development. Let’s
								elevate your design practice to new heights of success and
								creativity!
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Bespoke Coaching for Interior Designers
							</h3>
							<p>
								In the highly competitive and ever-evolving world of interior
								design, possessing artistic talent is just the starting point.
								Success in this industry also requires a strategic approach to
								business management. This involves utilising your unique design
								style, tapping into new market segments, and crafting pricing
								strategies that reflect the value of your work and enhance your
								profitability.
							</p>
							<p>
								With over 40 years of business experience and more than seven
								years dedicated to specialised coaching, I bring a variety of
								knowledge specifically suited to helping creative professionals
								like interior designers overcome these typical business
								challenges. During our coaching sessions, we'll address critical
								issues such as leveraging the latest design technologies,
								adapting to changing consumer tastes, and navigating the demands
								of the design industry. Together, we will develop strategies
								that not only sustain your business but also help it thrive,
								ensuring that your operations align seamlessly with your
								creative aspirations.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">
								How Business Coaching can bring new colours in to your business
							</h3>

							<p>
								For interior designers, the journey extends far beyond creating
								beautiful, functional spaces, it involves transforming these
								creative solutions into a sustainable and profitable business.
								This is where the expertise of a business coach becomes
								invaluable, offering guidance specifically tailored to the
								unique challenges faced by design professionals.
							</p>
							<p>
								Imagine the potential transformation of your business with the
								right strategies in place:
							</p>
							<ul className="pl-4">
								<li>
									Use your distinct design philosophy to attract and retain
									clients who value and are willing to invest in high-quality
									interior design. Customise your marketing efforts to connect
									deeply with your ideal demographic, fostering lasting
									relationships.
								</li>
								<li>
									Consider broadening your services to include consultations,
									virtual design, or partnerships with other creatives, opening
									up new avenues for revenue and enhancing your market appeal.
								</li>
								<li>
									Refine your pricing model to reflect the quality and
									uniqueness of your designs. A business coach can assist you in
									strategically positioning your services in the market,
									ensuring that your pricing structure accommodates for your
									creativity and expertise.
								</li>
								<li>
									Grow your team to support larger projects and increase
									business capacity, all while maintaining the high standard of
									design and personal touch that defines your brand.
								</li>
							</ul>
							<p>
								Utilising a business coach experienced in the interior design
								industry can provide you with the necessary insights and tools
								to tackle these challenges. Together, you can develop a
								strategic plan that not only meets but surpasses your business
								goals, turning your passion for design into a thriving
								enterprise.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">So, what’s been stopping you?</h2>
							<p>
								Do you find yourself overwhelmed by the constant demands of
								deadlines and client projects, making it difficult to focus on
								strategic growth for your interior design business? Whether it's
								about expanding your services or fine-tuning your pricing models
								to boost profitability, you're certainly not alone in these
								challenges.
							</p>{" "}
							<p>
								Here's how a specialised Business & Executive Coach can help you
								navigate these complex issues:
							</p>
							<ul className="pl-4">
								<li>
									Gain customised guidance on expanding your portfolio and
									exploring new market opportunities that resonate with your
									unique design aesthetic.
								</li>
								<li>
									Receive expert advice on adjusting your pricing strategies to
									accurately reflect the value of your designs and improve your
									profitability.
								</li>
								<li>
									Get support in enhancing your current services or developing
									new offerings that distinguish you from your competitors.
								</li>
							</ul>
							<p>
								Now is the ideal time to address these hurdles with the support
								of an experienced, impartial, and confidential business coach.
								Let's collaborate to transform your creative vision into a
								thriving, profitable interior design business.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								How executive coaching and mentoring works
							</h2>
							<p>
								As an international executive coach based in London, my job is
								to help and support leaders like you to find your way forward,
								set goals and hold you accountable. Together we’ll get you to
								where you want to be and tackle the ever-changing work
								environment head-on.
							</p>
							<p>
								With virtual executive coaching sessions at a mutually
								convenient time that cover:
							</p>
							<ul className="pl-4">
								<li>Professional and personal development</li>
								<li>Leadership coaching</li>
								<li>Business coaching</li>
								<li>Career coaching</li>
							</ul>
							<p>
								Unlike some executive coaches in London with a pre-prepared
								coaching process - these sessions are tailor-made for you and
								designed to give you or your organization a way forward, with
								the direction you need to thrive and build a positive future for
								yourself and those around you.
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="py-5 ">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Empowering your business with coaching
							</h2>

							<p>
								As an experienced business and executive coach, I am familiar
								with the unique challenges that interior designers face day to
								day. By partnering with me, you'll gain access to personalised
								coaching sessions tailored specifically to your needs as a
								creative professional. We'll focus on both your personal growth
								and professional development, ensuring you stay ahead of your
								competitors.
							</p>
							<p>
								My coaching method offers tailored support in a confidential
								setting, allowing us to tackle your specific challenges and
								seize new opportunities. This comprehensive approach is designed
								to convert your creative talent into a profitable and successful
								business.{" "}
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">About me as a business coach</h2>
							<p>
								As a business coach dedicated to supporting interior designers,
								my goal is to help you not only produce visually stunning spaces
								but also run a profitable and sustainable business. In our
								coaching sessions, we create a safe and private environment
								where you can thoroughly examine and enhance your business
								practices.
							</p>
							<p>
								If you're an interior designer looking to elevate your career
								and transform your business approach, let's start your business
								coaching journey with a discovery call. Together, we'll embark
								on a path to refine your strategies and ensure your creative
								endeavours successful.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Reviews from my business and executive coaching clients
							</h2>
						</Col>
					</Row>

					<HorizontalTestimonial
						name="Jessica Cox. Interior Designer. Jessica Cox Ltd"
						review="I have just worked with Jason on an MOT for my business, and even after the first session I had a much clearer idea on what I needed to change and focus on moving forward. I am excited to put the action points in place and implement the changes (in fact I already have and am already noticing a difference)! Jason is great to work with and is really encouraging whilst challenging the areas that I needed to relook at, so I know now what my focus is, which in turn is going to help my business grow. I can fully recommend the MOT service for any small business - it's invaluable. Now looking forward to reporting back and telling him all about the progress!"
						linkedIn="https://www.linkedin.com/in/jessica-cox-63358948"
						profilePic={jessC}
					/>

					<HorizontalTestimonial
						name="Laurence Katz. Elemental Studio Ltd"
						review="I've worked with Jason for the last 6 months and he has seen me through the ups and downs of business ownership during a tough time. With Jason's help, I've managed to find my mojo back, planned the next 5 years of my studio and I started outsourcing vital tasks which are proving to be a huge help in scaling up my whole organisation. Thank you very much for your help and guidance through that process"
						linkedIn="https://www.linkedin.com/in/laurence-katz/"
						profilePic={laurenceK}
					/>

					<HorizontalTestimonial
						name="Inga Kopala. Amberth Kitchens Bathrooms and Interiors"
						review="Having Jason as my business coach has been incredibly beneficial. He excels at holding me accountable, ensuring that I stay focused on my goals. The questions he asks are always insightful, leading to new perspectives and innovative solutions. His experience and knowledge are a constant source of guidance. I particularly value the flexibility of our sessions; we're not confined to a set program, which allows us to address the most pressing areas of my business. This customized approach has helped me to work towards my short-term goals and stay on track for my three-year growth plans."
						linkedIn="https://www.linkedin.com/in/inga-kopala-2a82012/"
						profilePic={IngaK}
					/>
				</Container>
			</section>
			{/* <section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Break the cycle with executive coaching in London
							</h3>
							<p>
								In this 24/7 world, leaders, senior managers, owners and
								executives are so busy that they don’t have the time to devote
								to their own development or to the development of the people who
								work for them. In other words, they’re caught in a vicious cycle
								of always “being busy.”
							</p>
							<p>
								In the same way, some struggle and worry about not being able to
								think clearly. You may have an idea, but you can’t seem to get a
								clear picture on what to do next, or perhaps you need to make
								decisions, but you don’t feel confident that the decision you
								make will be the right one.
							</p>
							<p>
								This is precisely why you need executive coaching services. As
								an executive coach in London, I can help you break the cycle of
								always being busy while giving you the confidence to make
								decisions that positively impact your life.
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Ready to work towards that positive future?
							</h2>
							<p>
								Unfortunately, though, none of us have a crystal ball. I can’t
								tell you what life will be like if you do, take that job, wake
								up filled with confidence, or grow your business tenfold - but
								what I can tell you is...
							</p>
							<p>If you do nothing, nothing will change.</p>
							<p>
								On the other hand, it’s amazing what we can achieve together
								when we use coaching to focus on what you really want.
							</p>
							<p>
								So, with nothing to lose - why not book a introductory call with
								me?
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Let's schedule a discovery call together</h3>
							<p>
								Why not schedule a discovery call with me? This relaxed
								conversation is a great chance for us to get to know each other
								and discuss how personalised business and executive coaching can
								help surpass your professional goals as an interior designer.
							</p>
							<p>
								Together, we'll work to boost your business skills whilst
								including your creative essence, ensuring your interior design
								business flourishes both creatively and financially.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default InteriorDesignersPage;
